/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');
// require('bootstrap-star-rating');


// $(document).ready(function() {
//     $('[data-toggle="popover"]').popover();
// });
class Banner {
    constructor(contenttext) {
        this.contenttext = contenttext
        this.body = document.querySelector('body')
        this.accepptb = document.createElement('button')
        this.denyb = document.createElement('button')
        this.container = document.createElement('div')
    }
    generate() {
        this.container.id = 'cookiebanner'
        //const text = document.createTextNode(this.contenttext)
        this.accepptb.id = 'acceppt'
        const acceppttext = document.createTextNode('Akzeptieren')
        this.accepptb.appendChild(acceppttext)
        this.denyb.id = 'ablehnen'
        const denytext = document.createTextNode('Ablehnen')
        this.denyb.appendChild(denytext)
        this.container.innerHTML = this.contenttext
        this.container.appendChild(this.accepptb)
        this.container.appendChild(this.denyb)
        this.body.appendChild(this.container)
        this.denyb.addEventListener('click',this.deny)
        this.accepptb.addEventListener('click',this.acceppt)
    }
    deny() {
        document.cookie = "banner=deny"
        const remove = document.querySelector('#cookiebanner')
        remove.parentElement.removeChild(remove)
    }
    acceppt() {
        document.cookie = "banner=acceppt"
        const remove = document.querySelector('#cookiebanner')
        remove.parentElement.removeChild(remove)
        //document.body.removeChild(document.querySelector(remove))
    }
    generateFunctions() {
        
    }
}



window.addEventListener('DOMContentLoaded',function(){
    if (
        !document.cookie.split(";").some((item) => item.trim().startsWith("banner="))
      ) {
        const banner = new Banner('<div>Wir benutzen Cookies nur für Anzeige der Anreisekarte. Sie können die Cookies akzeptieren oder ablehnen</div>')
        banner.generate()
      }
    
})



